<template>
    <div></div>
</template>

<script>

export default {
    props: {
        width: { Number, default: 0 },
        height: { Number, default: 0 }
    },

    mounted() {
        this.$el.style.width = this.width + 'rem';
        this.$el.style.height = this.height + 'rem';
    }
}

</script>
