<template>
    <div class="flex flex-col text-[1.7rem]">
        <span :class="[disabled ? 'opacity-50' : '']">{{ title }}</span>

        <G_Break height="0.25"/>

        <template v-if="disabled">
            <input v-model="value" spellcheck="false" autocomplete="off"
            class="px-[0.5em] pt-[0.4em] pb-[0.35em] bg-transparent outline-none border-[0.1rem] border-light-1 rounded-[0.25em] opacity-50 pointer-events-none transition">
        </template>

        <template v-else>

            <input v-model="value" :type="type" spellcheck="false" :autocomplete="value === '' ? autocomplete : 'off'"
            class="px-[0.5em] pt-[0.4em] pb-[0.35em] bg-transparent outline-none caret-light-1 border-[0.1rem] border-light-1 rounded-[0.25em] hover:border-orange-1 focus:border-orange-1 transition"
            :class="[this.valid ? 'border-light-300' : 'border-red-1', this.disabled ? 'bg-red' : '']">

            <G_Break height="0.5"/>

            <span class="text-[0.75em] transition"
            :class="[!this.valid ? 'text-red-1' : 'text-transparent select-none']">
                {{ this.validator.$errors[0]?.$validator == null ? '‎' : this.validator.$errors[0]?.$message }}
            </span>

        </template>
    </div>
</template>

<script>

export default {
    emits: ['emitValue'],

    props: {
        width: { Number },
        title: { String },
        type: { String },
        startValue: { String },
        disabled: { Boolean },
        autocomplete: { String },
        validator: { Object }
    },

    data() {
        return {
            value: ''
        }
    },

    computed: {
        valid()
        {
            return !this.validator.$dirty || !this.validator.$invalid;
        }
    },

    mounted() {
        if (this.startValue) this.value = this.startValue;

        this.$el.style.width = this.width + 'rem';
    },

    updated() {
        this.$emit('emitValue', this.value);
    }
}

</script>
