import { globalProperties } from '@/plugins/app/_config/main'

const AUTH_URI = 'auth';
const DATA_ATTRIBUTE = 'data';

const TOKEN_EXPIRED_MESSAGE = 'Token has expired';
const TOKEN_INVALID_MESSAGES = [
    'Token not provided',
    'The token has been blacklisted',
    'Token has expired and can no longer be refreshed',
    'The token could not be parsed from the request',
    'Wrong number of segments',
];

export default class {

    // HEADERS

    static headers()
    {
        const token = globalProperties.$storage.getToken();

        return { 'Authorization': `Bearer ${token}` };
    }

    // CALL

    static async call(method, uri, parameters)
    {
        const originalMethod = method;
        const originalUri = uri;
        const [originalData, originalConfig] = this.getDataAndConfig(parameters);

        originalConfig.headers = this.headers();

        // Filter out null
        const originalParameters = [originalData, originalConfig].filter(n => n);

        try {
            let response = await globalProperties.$axios_call[originalMethod](originalUri, ...originalParameters);

            response = response.data[DATA_ATTRIBUTE];

            this.checkForToken(response);

            return response;
        }
        catch (e) {
            let error = this.getError(e.response);

            if (error === TOKEN_EXPIRED_MESSAGE) {
                try {
                    const refresh = await globalProperties.$axios_call['post'](AUTH_URI + '/refresh', {}, { headers: this.headers() });

                    this.checkForToken(refresh.data[DATA_ATTRIBUTE]);

                    return await this.call(originalMethod, originalUri, originalParameters);
                }
                catch (e) {
                    error = this.getError(e.response);
                }
            }

            if (TOKEN_INVALID_MESSAGES.includes(error)) {
                globalProperties.$storage.unsetToken();
                globalProperties.$routing.replace('Login');

                error = null;
            }

            if (error) {
                globalProperties.$notify({ type: 'error', text: error });
            }

            return false;
        }
    }

    // TOKEN

    static checkForToken(response)
    {
        if (response.token) {
            globalProperties.$storage.setToken(response.token);
        }
    }

    // DATA AND CONFIG
    static getDataAndConfig(data)
    {
        if (data.length === 2) {
            return [data[0], data[1]];
        }

        return [null, data[0]];
    }

    // ERROR

    static getError(response)
    {
        let error = 'Error';

        if (response?.data) {
            if (typeof response.data.error === 'string') {
                error = response.data.error;
            }

            if (typeof response.data.error === 'object') {
                error = Object.values(response.data.error)[0];
            }
        }

        return error;
    }

    // GET

    static get(uri, config = {})
    {
        return this.call('get', uri, [config]);
    }

    static auth_get(uri, config = {})
    {
        return this.get(AUTH_URI + '/' + uri, config);
    }

    // POST

    static post(uri, data = {}, config = {})
    {
        return this.call('post', uri, [data, config]);
    }

    static auth_post(uri, data = {}, config = {})
    {
        return this.post(AUTH_URI + '/' + uri, data, config);
    }
}
