const AUTH_TOKEN_ITEM_KEY = 'auth_token';

export default class
{
    static getToken()
    {
        return localStorage.getItem(AUTH_TOKEN_ITEM_KEY);
    }

    static setToken(token)
    {
        localStorage.setItem(AUTH_TOKEN_ITEM_KEY, token);
    }

    static unsetToken()
    {
        localStorage.removeItem(AUTH_TOKEN_ITEM_KEY);
    }
}
