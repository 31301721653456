<template>
    <button type="button"
    class="w-max px-[0.75em] py-[0.5em] bg-orange-2 text-light-2 text-[1.7rem] font-[600] tracking-[0.03em] rounded-[0.25em] border-[0.25rem] border-transparent hover:border-light-1 cursor-pointer transition">{{ text }}</button>
</template>

<script>

export default {
    props: {
        text: { String, default: null }
    }
}

</script>
