import { createApp } from 'vue'
import App from './App.vue'

import '@/plugins/app/_themes/tailwind.css'

import router from './router'
import notifications from '@kyvg/vue3-notification'

import axios from '@/plugins/app/_scripts/axios'
import axios_call from 'axios'
import routing from '@/plugins/app/_scripts/routing'
import storage from '@/plugins/app/_scripts/storage'

const global = {
    install(app) {
        app.config.globalProperties.$setValue = (parent, property, value) => {
            parent[property] = value;
        }

        app.config.globalProperties.$axios = axios;
        app.config.globalProperties.$axios_call = axios_call.create({ baseURL: process.env.VUE_APP_BASE_URL });
        app.config.globalProperties.$routing = routing;
        app.config.globalProperties.$storage = storage;
    }
}

// App
const app = createApp(App);

// Global Components
import Components from '@/plugins/app/_scripts/components'

Components.all().forEach(function (item) {
    app.component(item.name, item.component);
});

// Global Properties
const globalProperties = app.config.globalProperties
export { globalProperties }

app
.use(router)
.use(notifications)
.use(global)
.mount('#app');
