import { globalProperties } from '@/plugins/app/_config/main'

export default class
{
    // Redirect without adding to history
    static replace(name)
    {
        globalProperties.$router.replace({ name });
    }

    // Redirect and add to history
    static push(name)
    {
        globalProperties.$router.push({ name });
    }
}
