import { createRouter, createWebHistory } from "vue-router"

import { globalProperties } from '@/plugins/app/_config/main'

const routes = [
    {
        path: '/login',
        name: 'Login',
        component: () => import('@/plugins/app@auth/_screens/s-login.vue'),
    },
    {
        path: '/register',
        name: 'Register',
        component: () => import('@/plugins/app@auth/_screens/s-register.vue'),
    },



    {
        path: '/activate',
        name: 'Activate',
        component: () => import('@/plugins/app@auth/_screens/s-activate.vue'),
    },
    {
        path: '/reset',
        name: 'Reset',
        component: () => import('@/plugins/app@auth/_screens/s-reset.vue'),
    },



    {
        path: '/account',
        name: 'Account',
        component: () => import('@/plugins/app@account/_screens/s-account.vue'),
        meta: { requiresAuth: true },
    },



    {
        path: '/:catchAll(.*)',
        name: 'NotFound',
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes: routes,
    scrollBehavior() {
        return { top: 0 }
    },
})

router.beforeEach((to, from, next) => {
    if (to.meta.requiresAuth === true) {
        if (!hasToken()) next({ name: "Login" });
        else next();
    } else {
        if (to.name === "NotFound") {
            if (!hasToken()) next({ name: "Login" });
            else next({ name: "Account"} );
        }  
        else if (to.name === "Login" || to.name === "Register") {
            if (hasToken()) next({ name: "Account" });
            else next();
        }
        else {
            next();
        }
    }
})

function hasToken()
{
    return globalProperties.$storage.getToken();
}

export default router
