<template>
    <div class="flex px-[3rem] py-[1.5rem] justify-between bg-gradient-to-t from-dark-2 to-dark-orange-2 border-b-[0.1rem] border-orange-2">
        <div class="flex">
            <img src="@/assets/images/logo.png"
            class="w-[4.5rem] h-[4.5rem] object-cover">

            <G_Break width="2"/>

            <span class="text-[3rem] select-none">AWS EC2 TEST</span>
        </div>
    </div>
</template>