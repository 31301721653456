import G_Break from '@/plugins/app/_components/g-break'
import G_Input from '@/plugins/app/_components/g-input'
import G_Header from '@/plugins/app/_components/g-header'
import G_Container from '@/plugins/app/_components/g-container'
import G_ButtonPrimary from '@/plugins/app/_components/g-button-primary'
import G_ButtonSecondary from '@/plugins/app/_components/g-button-secondary'

export default class
{
    static all()
    {
        return [
            { name: 'G_Break', component: G_Break },
            { name: 'G_Input', component: G_Input },
            { name: 'G_Header', component: G_Header },
            { name: 'G_Container', component: G_Container },
            { name: 'G_ButtonPrimary', component: G_ButtonPrimary },
            { name: 'G_ButtonSecondary', component: G_ButtonSecondary },
        ];
    }
}
