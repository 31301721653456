<template>
    <div class="flex flex-col items-center cursor-pointer group">
        <button type="button"
        class="bg-transparent text-light-1 text-[1.25rem] tracking-[0.03em] cursor-pointer transition">{{ text }}</button>

        <div class="w-0 h-[0.1rem] mt-[0.75rem] bg-orange-1 group-hover:w-full transition-[width]"></div>
    </div>
</template>

<script>

export default {
    props: {
        text: { String }
    }
}

</script>
