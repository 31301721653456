<template>
    <div class="flex w-full h-full justify-center items-center opacity-0 transition">
        <slot></slot>
    </div>
</template>

<script>

export default {
    mounted() {
        setTimeout(function () {
            this.$el.style.opacity = '100%';
        }.bind(this), 0.25 * 1000);
    }
}

</script>
