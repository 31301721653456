<template>
    <G_Header/>
    <router-view></router-view>

    <notifications classes="my-notification" width="100%" position="bottom center"/>
</template>

<script>

export default {
    name: 'App'
}

</script>

<style>

/*#region Fonts*/

* {
    font-family: 'Rubik', Arial, Helvetica, sans-serif;
    color: var(--light-1);
}

/*#endregion*/

/*#region Variables*/

:root{
    --dark-1: #181716;

    --light-1: #BCBCBC;

    --orange-2: #BE692E;
    --dark-orange-1: #261509;
    --red-1: #b91c1c;
    --dark-red-1: #450a0a;
    --green-1: #15803d;
    --dark-green-1: #052e16;
}

/*#endregion*/

/*#region Responsive Design*/

@media (min-width: 0) {
    html {
        font-size: 2px;
    }
}

@media (min-width: 620px) {
    html {
        font-size: 5px;
    }
}

@media (min-width: 768px) {
    html {
        font-size: 6px;
    }
}

@media (min-width: 1024px) {
    html {
        font-size: 8px;
    }
}

@media (min-width: 1280px) {
    html {
        font-size: 10px;
    }
}

@media (min-width: 1536px) {
    html {
        font-size: 11px;
    }
}

/*#endregion*/

/*#region Base*/

html {
    overflow-x: hidden;
    overflow-y: scroll;
}

html, body, #app{
    display: flex;
    flex-direction: column;

    max-width: 100%;
    height: 100%;

    transition: none;

    background: linear-gradient(to top left, var(--dark-1), var(--dark-orange-1));
}

header, body{
    margin: 0;
}

div, span {
    -webkit-user-drag: none;
}

img, button, input, a {
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

/*#endregion*/

/*#region Notification*/

.my-notification {

    width: max-content;
    margin: 3rem auto;

    background-color: var(--dark-orange-1);
    border-color: var(--orange-2);

    border-width: 0.1rem;
    border-radius: 0.425rem;

    .notification-content {
        padding: 1rem 2rem;

        text-align: center;
        font-size: 1.7rem;
    }

    &.success {
        background-color: var(--dark-green-1);
        border-color: var(--green-1);
    }
    &.info {
        background-color: var(--dark-orange-1);
        border-color: var(--orange-2);
    }
    &.error {
        background-color: var(--dark-red-1);
        border-color: var(--red-1);
    }
}

/*#endregion*/

/*#region Scrollbar*/

::-webkit-scrollbar {
    display: none;
}

/*#endregion*/

/*#region AutoFill*/

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
    -webkit-text-fill-color: var(--light-1) !important;
    -webkit-box-shadow: 0 0 0 30px transparent inset !important;
    transition: background-color 5000s ease-in-out 0s;
    caret-color: var(--light-1);
}

/*#endregion*/

</style>
